.garantie {
	&__slider {
		position: relative;
		width: 100%;
		height: 200px;
		overflow: hidden;
		background: url('/wp-content/themes/busbaan/assets/images/bg-baangarantie-mobile.jpg') center center;
		background-size: cover;

		&__start {
			padding: 40px 0;
			text-align: center;
			transition: opacity .2s;

			&__title {
				display: block;
				padding: 0 90px;
				margin-top: 10px;
				color: #fff;
				font-weight: bold;
			}

			&--hidden {
				opacity: 0;
			}
		}

		&__image {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: flex-start;
			text-align: center;
			padding-top: 13px;

			&__numb {
				display: inline-block;
				width: 80px;
				height: 80px;
				font-size: 100px;
				line-height: 100px;
				font-weight: bold;
				color: $c-blue;
				background: #fff;
				border-radius: 50%;
			}

			&__title {
				position: relative;
				margin: 33px 0 0 10px;
				color: #fff;
				font-size: 45px;
				font-weight: 700;
			}
		}
	}

	&__content {
		padding: 25px 23px;
		color: #fff;
		overflow: hidden;
		background: $c-blue;

		&__wrapper {
			display: flex;
			width: calc(100vw * 3);
			transition: transform 1s ease-in-out;
		}

		&__item {
			width: calc(100vw - 46px);
			margin-right: 23px;
		}

		&__title {
			display: block;
			margin-bottom: 10px;
		}
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 20px;
		margin: 30px 0;

		svg {
			&:first-of-type {
				margin-right: 25px;
			}

			&:last-of-type {
				margin-left: 25px;
			}
		}

		&__item {
			display: inline-block;
			width: 8px;
			height: 8px;
			margin-top: 5px;
			background: #fff;
			border-radius: 50%;
			opacity: .35;

			&--active {
				opacity: 1;
			}
		}
	}


	// BREAKPOINTS
	@include bp(tablet) {
		margin-bottom: 40px;

		&__slider {
			height: 404px;
			background-image: url('/wp-content/themes/busbaan/assets/images/bg-baangarantie.jpg');

			&__start {
				padding: 80px 0;

				&__title {
					font-size: 24px;
				}
			}

			&__image {
				padding-top: 30px;

				&__numb {
					width: 140px;
					height: 140px;
					font-size: 180px;
					line-height: 168px;
				}

				&__title {
					margin: 65px 0 0 25px;
					font-size: 55px;
				}
			}
		}

		&__content {
			padding: 50px 80px;

			&__item {
				width: calc(100vw - 160px);
				margin-right: 80px;
			}
		}

		&__nav,
		&__cta {
			position: relative;
			left: 50%;
			transform: translate(-50%, 0);
		}

		&__nav {
			max-width: 220px;
			margin: 20px 0;
		}

		&__cta {
			max-width: 240px;
		}
	}

	@include bp(desktop) {
		display: flex;

		&__slider {
			flex-basis: 50%;
		}

		&__content {
			flex-basis: 50%;
			padding: 40px 50px;

			&__wrapper {
				width: calc(50vw * 3);
			}

			&__item {
				width: calc((1200px / 2) - 100px);
				margin-right: calc((100vw - 1200px) / 2 + 50px);
			}
		}

		&__nav,
		&__cta {
			margin-left: 150px;
			left: 0;
			transform: translate(0, 0);
		}
	}
}