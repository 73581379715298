.vacatures {
	display: flex;
	flex-wrap: wrap;
	padding: 60px 0;

	&__list {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-left: 0;
		list-style: none;

		&__item {
			display: flex;
			flex-basis: 100%;
			padding: 15px 0 10px;
			cursor: pointer;
			border-bottom: 1px solid $c-border;

			&__txt {
				flex-grow: 2;

				&__title {
					display: block;
					font-size: 22px;
					color: $c-blue;
					margin-bottom: -4px;
				}

				&__info {
					font-size: 16px;

					&:after {
						content: '|';
						display: inline-block;
						margin: 0 1px 0 4px;
					}

					&:last-of-type:after {
						display: none;
					}
				}
			}

			&__arrow {
				transform: rotate(90deg);
				transition: transform .2s;
			}

			&--active .vacatures__list__item__arrow {
				transform: rotate(270deg);
			}
		}
	}

	&__content {
		display: none;
		padding: 25px 23px;
		background: $c-border;

		&--active {
			display: block;
		}

		> h4:first-child {
			margin-bottom: 20px;
		}

		a.btn {
			width: 50%;
			text-align: center;
		}
	}


	// BREAKPOINTS
	@include bp(tablet) {
		align-content: flex-start;

		&__list {
			flex-basis: 100%;

			&__item {
				width: 35%;

				&__arrow {
					transform: rotate(0deg);
				}

				&--active .vacatures__list__item__arrow {
					transform: rotate(180deg);
				}
			}
		}

		&__content {
			position: absolute;
			top: 0;
			right: 0;
			width: 62%;
			padding: 40px;
		}
	}

	@include bp(desktop) {
		&__list {
			flex-direction: column;
			flex-wrap: nowrap;

			&__item {
				width: 48%;
				min-height: 70px;
			}
		}

		&__content {
			width: 50%;
		}
	}
}