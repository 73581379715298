.usp {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 25px 23px;
	background: $c-border;

	&__title {
		flex-basis: 100%;
		margin-bottom: 60px;
		color: $c-blue;
	}

	&__item {
		padding: 0 0 20px;
		color: $c-blue;
		text-align: center;
		border-bottom: 1px solid #fff;

		&:last-of-type {
			border-bottom: 0;
		}

		&__txt:only-of-type {
			display: block;
			margin-bottom: 50px;
		}
	}

	&__header {
		margin-bottom: 5px;
	}

	// Exceptions
	&--meter {
		margin-bottom: 0;
		background: none;

		.container {
			padding: 0;
			background: none;
		}

		.usp__item {
			flex-basis: 100%;
		}

		.usp__header__title {
			width: 100%;
			margin-top: 20px;
			font-size: 23px;
			font-weight: 700;
			letter-spacing: 0;
			text-align: center;
			color: $c-light;
		}
	}



	// BREAKPOINTS
	@include bp(tablet) {
		flex-direction: row;
		justify-content: space-between;
		padding: 0 30px 30px;
		margin-bottom: 80px;

		.container {
			padding: 0;
			justify-content: space-between;
		}

		&__title {
			padding: 0 65px;
		}

		&__item {
			flex-basis: 32%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 0;
			border-bottom: 0;
		}

		// Exceptions
		&--meter {
			padding: 50px 0 10px;
			margin-bottom: 0;

			.container {
				padding: 0 70px;
			}

			.usp__item {
				flex-basis: 48%;
			}
		}
	}

	@include bp(desktop) {
		margin-bottom: 100px;
		padding: 0 75px;
		background: none;

		.container {
			padding: 10px 70px 50px;
			background: $c-border;
		}

		&__item {
			flex-basis: 28%;
			justify-content: flex-start;

			&__txt {
				flex-grow: 2;
			}

			.btn {
				padding: 12px 30px 10px;
			}
		}


		// Exceptions
		&--meter {
			padding: 60px 0 0;
			margin-bottom: 0;

			.container {
				background: none;
			}

			.usp__item {
				flex-basis: 22%;
			}
		}
	}
}