.page {

	&__header {
		min-height: calc(100vh - 95px);
		padding: 50px 0;
		background-position: center;
		background-size: cover;

		.container {
			display: flex;
			flex-direction: column;
		}

		&__title {
			text-align: center;
			margin-bottom: 30px;

			&--blue,
			&--orange {
				color: $c-blue;
			}
		}

		&__txt {
			display: flex;
			flex-direction: column;
			padding: 30px;
			background: #ebecec;

			&__cta {
				align-self: center;
				margin-top: 20px;
			}

			&--blue,
			&--orange {
				color: #fff;
				background: $c-blue;
			}
		}
	}

	&__title {
		display: block;
		width: 100%;
		margin-bottom: 20px;
		color: $c-blue;
		text-align: center;
	}


	// Exceptions
	&--single {
		padding: 50px 0;
	}


	// BREAKPOINTS
	@include bp(tablet) {
		&__header {
			min-height: 400px;

			.container {
				padding: 0 100px;

				&--no-padding {
					padding: 0 25px;
				}
			}

			&__txt {
				padding: 40px 50px;
			}
		}
	}

	@include bp(desktop) {
		&__header {
			min-height: 600px;
			padding: 100px 0;

			.container {
				&--no-padding {
					padding: 0 100px;
				}
			}

			&__title {
				padding: 0 220px;
				margin-bottom: 30px;
			}

			&__txt {
				padding: 50px 135px;
			}
		}
	}
}