.cta {
	width: 100%;
	padding: 40px 23px;
	text-align: center;
	color: #fff;
	background: url('/wp-content/themes/busbaan/assets/images/bg-bewegend-mobile.jpg') center center;
	background-size: cover;

	&__title {
		position: relative;
		z-index: 3;
		line-height: 31px;
	}

	&__btn {
		position: relative;
		z-index: 3;
		display: inline-block;
		height: 110px;
		padding: 50px 20px 0;
		margin-top: 30px;
		color: #fff;
		font-size: 28px;
		font-weight: 900;
		text-transform: uppercase;

		&--mobile {
			height: 120px;
		}
	}


	// BREAKPOINTS
	@include bp(tablet) {
		flex-basis: 100%;
		height: 450px;
		padding: 100px 10vw;

		&__title {
			line-height: 32px;
			margin-bottom: 30px;
		}

		&__btn {
			letter-spacing: 1px;
			font-size: 30px;
			margin-top: 60px;
		}

		// Small title
		&--small-title .cta__title {
			padding: 0 17vw;
			line-height: 34px;
		}
	}

	@include bp(desktop) {
		padding: 90px 0;
		background-image: url('/wp-content/themes/busbaan/assets/images/bg-bewegend.jpg');

		&__txt {
			padding: 0 30vw;;
		}

		&__btn {
			min-width: 490px;

			&:hover {
				.cta__btn__bg {
					fill: rgba(255, 255, 255, .2);
				}
			}
		}

		// Small title
		&--small-title .cta__title {
			padding: 0 30vw;
		}
	}
}