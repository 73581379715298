.icon {
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: middle;
	fill: currentColor;

	&--stroke {
		stroke: currentColor;
	}

	&--logo {
		width: 220px;
		height: 50px;

		&--footer {
			width: 100%;
			height: 50px;
		}
	}

	&--menu {
		margin: -1px 5px 0 0;
		fill: $c-blue;
	}

	&--arrow {
		align-self: center;
		min-width: 20px;
		min-height: 20px;

		&--big {
			width: 80px;
			height: 80px;
		}

		&--submit {
			width: 34px;
			height: 34px;
			transition: transform .2s;

			&:hover {
				transform: rotate(90deg);
			}
		}
	}

	&--loader {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100px;
		height: 100px;
		transform: translate(-50%, -50%);
	}

	&--social {
		width: 30px;
		height: 30px;
	}

	// Home
	&--usp {
		width: 50px;
		height: auto;
	}

	// CTA
	&--lined-btn {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 100%;
		max-width: 510px;
		height: 90px;
		fill: none;
		transform: translate(-50%, 0);
		transition: fill .2s;
	}

	&--lined-truck {
		position: absolute;
		top: 0;
		left: 50%;
		height: 35px;
		width: 70px;
		transform: translate(-50%, 0);
	}

	&--star {
		width: 26px;
		height: 26px;
	}

	&--load-more {
		width: 40px;
		height: 40px;
		transform: rotate(90deg);
	}


	// BREAKPOINTS
	@include bp(tablet) {
		&--logo--footer {
			width: 230px;
			height: 50px;
		}

		&--arrow {
			&--big {
				width: 150px;
				height: 150px;
			}
		}

		// CTA
		&--lined-truck {
			margin-top: 6px;
		}
	}

	@include bp(desktop) {
		&--menu {
			display: none;
		}


		// CTA
		&--lined-truck {
			margin-top: 2px;
		}
	}
}