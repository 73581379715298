.social {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 20px;

	&__item {
		margin: 0 5px;
		transition: opacity .2s;
	}


	// BREAKPOINTS
	@include bp(tablet) {
		flex-basis: 60%;
		align-items: center;
		justify-content: center;
		margin-top: 0;
	}

	@include bp(desktop) {
		&__item:hover {
			opacity: .6;
		}
	}
}
