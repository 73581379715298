.contact {
	width: 100%;
	padding: 30px 0;
	margin-bottom: 60px;
	background: #ebecec;

	.container {
		justify-content: space-between;
	}

	&__location {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		background: #fff;
		margin: -30px 0 30px;

		&__item {
			color: $c-blue;
			flex-basis: 100%;
			font-size: 26px;
			font-weight: bold;
			padding: 15px;
			opacity: .5;

			&--active {
				opacity: 1;
			}
		}
	}

	&__item {
		flex-basis: 49%;
		order: 3;
		font-size: 22px;

		&:first-of-type {
			order: 1;
			margin-bottom: 30px;
		}

		&:last-of-type {
			order: 2;
			margin-bottom: 30px;
		}
	}

	&__title {
		width: 100%;
		margin-bottom: 15px;
		text-align: center;
		color: $c-blue;
	}

	// Google Maps
	&__maps {
		position: relative;
		width: 100vw;
		height: 100vh;

		&__load {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 10;
		}

		&__route {
			display: none;
		}
	}


	// BREAKPOINTS
	@include bp(tablet) {
		padding: 50px 0;

		&__location {
			margin: -50px 0 50px;
			justify-content: space-between;

			&__item {
				flex-basis: 33%;
				text-align: center;
				padding: 30px 0;
				font-size: 32px;
			}
		}

		&__item,
		&__item:last-of-type,
		&__item:first-of-type {
			flex-basis: auto;
			margin-bottom: 0;
			order: 1;
		}
	}

	@include bp(desktop) {
		.container {
			padding: 0 180px;
		}

		// Google Maps
		&__maps {
			height: 700px;

			&__route {
				position: absolute;
				top: 40px;
				left: 40px;
				z-index: 20;
				display: block;
				width: 340px;
				padding: 15px;
				background: $c-blue;

				&__directions {
					max-height: 500px;
					overflow: scroll;
					font-size: 11px;
					background: #fff;
				}
			}
		}
	}
}