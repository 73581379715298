html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins,
kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot,
thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, menu, time, mark, audio, video
{ margin:0; padding:0; border:0; outline:0; font-size:100%; vertical-align:baseline; background:transparent;}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body.modal-open {
	overflow: hidden;
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
}

body {
  &.sticky {
    padding-top: 95px;

    @include bp(tablet) {
      padding-top: 75px;
    }

    @include bp(desktop) {
      padding-top: 117px;
    }
  }
}

img,
video {
	max-width: 100%;
	border: none;

	&.alignleft {
		margin-right: 20px;
	}

	&.alignright {
		margin-left: 20px;
	}
}

ul {
	list-style: none;
}

p {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

iframe {
	max-width: 100%;
}

main ul,
main ol {
	list-style: disc;
	margin-left: 20px;
}

// No script
noscript {
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	padding: 10px;
	text-align: center;
	background: rgba(0,0,0, .5);
	z-index: 999999;

	div {
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		padding: 15px;
		background: yellow;
	}
}

// Messages
.wpcf7-display-none,
.screen-reader-response {
	display: none;
}

div.wpcf7-response-output  {
	width: 100%;
	float: left;
	padding: 10px 15px !important;
	margin: 20px 0 0 0 !important;
	border: 0;
	color: #fff;
	font-weight: bold;

	&.wpcf7-mail-sent-ok {
		border-color: $c-green;
		background: $c-green;
	}

	&.wpcf7-validation-errors {
		border-color: $c-red;
		background: $c-red;
	}
}

span.wpcf7-not-valid-tip {
	float: left;
	margin: 0 0 10px 2px;
	color: $c-red;
}