.statistics {
	width: 100vw;
	min-height: 600px;
	padding: 30px 23px;
	background-position: center;
	background-size: cover;

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	&__title,
	&__item {
		color: #fff;
		text-align: center;
	}

	&__title {
		width: 100%;
		margin-bottom: 20px;
		font-weight: 900;
		font-size: 38px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		width: 150px;
		margin-bottom: 30px;

		&:last-of-type {
			margin-bottom: 0;
		}

		&__numb {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 150px;
			height: 150px;
			word-break: break-all;
			line-height: 40px;
			padding: 20px 30px 0;
			color: $c-blue;
			font-size: 55px;
			font-weight: 700;
			overflow: hidden;
			background: #fff;
			border-radius: 50%;
			font-weight: 700;
			overflow: hidden;
		}

		&__title {
			padding: 0 15px;
			margin-top: 10px;
			font-weight: 700;
			font-size: 16px;
			line-height: 16px;
		}

		&__small {
			font-weight: 300;
			font-size: 20px;
			margin: 0 -20px;
		}
	}


	// BREAKPOINTS
	@include bp(tablet) {
		height: 500px;
		padding: 120px 0;

		&__wrapper {
			flex-direction: row;
			padding: 0 50px;
			align-items: flex-start;
			justify-content: space-between;
		}

		&__title {
			margin-bottom: 30px;
		}

		&__item {
			width: 250px;

			&__numb {
				width: 250px;
				height: 250px;
				line-height: 60px;
				font-size: 75px;
				padding: 20px 15px 0;
			}

			&__title {
				font-size: 24px;
				line-height: 30px;
			}
		}
	}

	@include bp(desktop) {
		height: 600px;
		padding: 120px 0;

		&__wrapper {
			padding: 0 120px;
		}

		&__item {
			margin-bottom: 0;
		}
	}
}