.btn {
  display: inline-block;
  padding: 12px 12px 10px;
  color: #fff;
  font-size: 26px;
  letter-spacing: 1px;
  border: 1px solid #fff;
  text-decoration: none;
  transition: border .2s, background .2s, color .2s;

  @include bp(desktop) {
    &:hover {
      color: $c-light;
      background: #fff;
    }
  }

  &--no-bg {
    color: #fff !important;
    border: none;
    background: transparent;

    @include bp(desktop) {
      &:hover {
        border: none;
        background: transparent;
      }
    }
  }

  &--white {
    color: $c-blue;
    background: #fff;
    border-color: $c-blue;

    @include bp(desktop) {
      &:hover {
        color: #fff;
        background: $c-blue;
      }

      &--on-blue:hover {
        color: $c-blue;
        background: #fff;
      }
    }
  }

  &--blue,
  &--orange {
    color: #fff;
    background: $c-blue;
    border-color: $c-blue;

    @include bp(desktop) {
      &:hover {
        color: $c-blue;
        background: #fff;
      }
    }
  }

  &--stretch {
    width: 100%;
    text-align: center;
  }

  &--small {
    font-size: 20px;
    font-weight: 500;
  }

  &--icon {
    display: flex;
    justify-content: space-between;

    .btn__txt {
      flex-grow: 2;
      font-size: 16px;
    }
  }

  &--load-more {
    color: $c-blue;
    cursor: pointer;
    margin-top: 20px;

    &__txt {
      margin: 1px 0 0 5px;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &[disabled] {
    opacity: .7;
  }
}