.form {
	position: relative;

	&__fieldset {
		display: flex;
		justify-content: space-between;
		min-height: 35px;
		margin-bottom: 10px;

		&:last-of-type {
			justify-content: flex-end;

			&:first-of-type {
				margin-bottom: 0;
			}

			.btn {
				//flex-direction: row;
				flex-basis: 49%;
				text-align: center;

				+ .btn {
					margin-left: 1%;
				}
			}
		}

		&__wrapper {
			display: flex;
			flex-wrap: wrap;
			flex-basis: 100%;
			align-content: flex-start;

			.form__fieldset {
				flex-basis: 100%;
			}
		}
	}

	&__label {
		display: inline-block;
		margin: 9px 12px 0 0;
	}

	&__loader,
	&__message {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity .2s;

		&--active {
			opacity: 1;
		}
	}

	&__loader {
		background: rgba(44, 97, 147, .6);

		&--active {
			z-index: 20;
		}
	}

	&__message {
		height: 0;
		padding: 20px 0;
		font-weight: bold;
		font-size: 22px;
		background: $c-blue;
		transition: opacity .2s, height .2s;

		&--active {
			z-index: 10;
			height: 100%;
		}
	}

	// Elements
	&__select,
	&__input {
		flex-grow: 2;
		padding: 10px 15px;
		font-size: 16px;
		outline: none;
		border: 1px solid $c-blue;
		background: #fff;
		border-radius: 2px;
		appearance: none;

		&.error {
			border-color: $c-red;
			box-shadow: 0 0 5px $c-red;
		}
	}

	textarea {
		max-width: 100%;
		min-height: 140px;
		max-height: 200px;
	}

	&__select {
		height: 40px;
		color: $c-grey;
		cursor: pointer;
		font-size: 15px;

		&:not([multiple]) {
			background: #fff url('/wp-content/themes/busbaan/assets/images/arrow.png') no-repeat right 10px top 50%;
			background-size: 10px auto;
		}

		option {
			&[disabled] {
				color: $c-grey;
			}
		}
	}

	&__submit {
		border: 0;
		outline: none;
		cursor: pointer;
		color: $c-blue;
		font-size: 16px;
		text-align: center;
		appearance: none;

		&[disabled] {
			opacity: .5;
			cursor: not-allowed;
		}

		&--arrow-only {
			background: none;
		}
	}


	// Exceptions
	&--align-fields {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.form__fieldset {
			flex-basis: 100%;
		}
	}


	// BREAKPOINTS
	@include bp(desktop) {
		&__fieldset:last-of-type.form__fieldset--start {
			justify-content: flex-start;
			padding-left: 31px;
		}


		// Exceptions
		&--align-fields {
			.form__fieldset {
				flex-basis: 49%;

				&__wrapper {
					flex-basis: 49%;

					.form__fieldset {
						flex-basis: 100%;
					}
				}
			}
		}
	}
}

::-webkit-input-placeholder {
	color: $c-grey;
	font-size: 20px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}

:-moz-placeholder { /* Firefox 18- */
	color: $c-grey;
	font-size: 20px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}

::-moz-placeholder {  /* Firefox 19+ */
	color: $c-grey;
	font-size: 20px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}

:-ms-input-placeholder {
	color: $c-grey;
	font-size: 20px;
	font-family: QuanRounded, Helvetica, 'sans-serif';
}