.doormat {
	position: relative;

	.container {
		flex-direction: column;
		padding: 0;
	}

	&__txt {
		padding: 25px 23px;
		color: #282828;
		background: #fff;

		&__title {
			display: block;
			margin-bottom: 10px;
		}
	}

	&__list {
		padding: 20px 0 35px 37px;
		margin-left: 0;
		list-style: none;
		color: #282828;
		background: #fff;

		&__item {
			position: relative;
			padding-left: 15px;
			margin: 12px 0;
			font-size: 22px;
			font-weight: 600;

			&:before {
				position: absolute;
				top: 1px;
				left: -15px;
				content: "";
				width: 18px;
				height: 18px;
				background: url('/wp-content/themes/busbaan/assets/images/icons/liststyle-wheel.png') top left;
				background-size: 100% 100%;
			}
		}
	}


	// BREAKPOINTS
	@include bp(tablet) {
		.container {
			flex-direction: row;
		}

		&__txt {
			padding: 50px 50px 50px 30px;
			width: 63%;
			//flex-basis: 63%;
		}

		&__list {
			width: 37%;
			//flex-basis: 37%;
			padding: 40px 30px 50px 10px;
		}
	}

	@include bp(desktop) {
		.container {
			justify-content: flex-start;
		}

		&__txt {
			width: 50%;
			//flex-basis: 50%;
			padding: 50px 100px 100px;
		}

		&__list {
			width: 25%;
			//flex-basis: 25%;
			padding: 40px 20px 50px;
			margin-left: 30px;

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				right: 0;
				height: 100%;
				width: 100vw;
				background: #fff;
			}
		}
	}
}