.container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-left: 23px;
	padding-right: 23px;

	&--column {
		flex-direction: column;
	}

	&--noflex {
		display: block;
	}


	// BREAKPOINTS
	@include bp(desktop) {
		max-width: 1200px;
		margin: 0 auto;
		padding-left: 35px;
		padding-right: 35px;
	}
}